import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/pricing_login.css';
import withAuth from '../users/withAuth';


function Pricing_login() {


    return (

    <div> 

        <Helmet>
            <title>Pricing</title>
        </Helmet>


        {/* section 1 */}
        <div className="col-md-12">
            <h2 className="center-heading-pricing-login">Pricing details</h2>
        </div>

        <br></br>

        {/* section 2 */}
        <div className="col-md-8">
            <table className="table-price-login">

                <thead>
                    <tr>
                        <th className='table-price-login-heading1'><h3>Service</h3></th>
                        <th className='table-price-login-heading'><h3>Pricing</h3></th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <p class="service-name-login"> Urdu Text-to-Speech </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.01 per character for a transaction over 100 characters</p> 
                            <p>PKR 1.00 per transaction for characters less than 100</p>  
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Punjabi Text-to-Speech </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.01 per character for a transaction over 100 characters</p> 
                            <p>PKR 1.00 per transaction for characters less than 100</p>  
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Urdu Part-of-Speech </p>
                        </td>
                        <td class="service-price-detail-login" height="80">                 
                            <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                            <p>PKR 0.25 per transaction for words less than 500</p>  
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Profanity Estimation </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.01 per 100 characters for a transaction over 500 characters</p>
                            <p>PKR 0.05 per transaction for characters less than 500 characters</p>  
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Language Identification </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.01 per 100 characters for a transaction over 500 characters</p>
                            <p>PKR 0.05 per transaction for characters less than 500 characters</p> 
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <p class="service-name-login"> Text Summarization </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.001 per character for a transaction over 1000 characters</p>
                            <p>PKR 1.0 per transaction for characters less than 1000 characters</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Domain Identification </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.001 per character for a transaction over 1000 characters</p>
                            <p>PKR 1.0 per transaction for characters less than 1000 characters</p>  
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Roman to Urdu Script </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 1.0 per request</p>
                            <br></br>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Spell Checking </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.01 per 100 characters for a transaction over 500 characters</p>
                            <p>PKR 0.05 per transaction for characters less than 500 characters</p> 
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Keyword Extraction </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                            <p>PKR 0.25 per transaction for words less than 500</p>   
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Urdu Speech to Text </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.75 per 15 seconds</p>
                            <p>Each request is rounded up to the nearest increment of 15 seconds</p>
                            <br></br>
                        </td>
                    </tr>

                   

                    {/* <tr>
                        <td>
                            <p class="service-name-login"> Speech-to-Speech </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 5.0 per request</p>
                            <br></br>
                        </td>
                    </tr> */}

                    {/* <tr>
                        <td>
                            <p class="service-name-login"> Camera Captured Address Recognition and Structuring </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>0.05 PKR for image less than or equal to 50Kb</p>
                            <p>0.01 PKR per 10KB for image greater than 50Kb</p>
                            <br></br>
                        </td>
                    </tr> */}
                    
                    <tr>
                        <td>
                            <p class="service-name-login"> Urdu Stemmer </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                            <p>PKR 0.25 per transaction for words less than 500</p> 
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p class="service-name-login"> Urdu OCR </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.5 for an image file of size less than or equal to 2MB</p>
                            <p>PKR 0.25 per 1MB for an image file of size greater than 2MB</p>                       
                            <br></br>
                            <span class="table-divider"></span>
                        </td>
                    </tr>

                    {/* <tr>
                        <td>
                            <p class="service-name-login"> Urdu Segmentation </p>
                        </td>
                        <td class="service-price-detail-login" height="80">
                            <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                            <p>PKR 0.25 per transaction for words less than 500</p>  
                        </td>
                    </tr> */}
                    <tr></tr>
                </tbody>
            </table>
        </div>



    </div>
      
    )
}

const PricingPageWithAuth = withAuth(Pricing_login);
export default PricingPageWithAuth;