import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import withAuth from '../users/withAuth';
import axios from 'axios';

import '../styles/home.css';

import image from '../images/home/image-services.png';
import speech from '../images/home/speech-services.png';
import text from '../images/home/text-services.png';

function Home() {

  const [jobList, setJobList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDisplayedJobs, setCurrentDisplayedJobs] = useState([]);

  const entriesPerPage = 25;

  const fetchJobData = async () => {
    try {
      const accessToken = localStorage.getItem('accesstoken');
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/history', { accessToken: accessToken, });
      // const response = await axios.post('http://localhost:8085/history', { accessToken: accessToken, });

      // Sort the job list by date and time in descending order
      const sortedJobs = response.data.sort((a, b) => {
      const dateA = new Date(`${a.date} ${a.time}`);
      const dateB = new Date(`${b.date} ${b.time}`);
      return dateB - dateA;
    });

    setJobList(sortedJobs);

    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleNextPage = () => {
    const totalPages = getTotalPages();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  useEffect(() => {
    fetchJobData();
  }, []);


  useEffect(() => {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = Math.min(startIndex + entriesPerPage, jobList.length);
    const currentPageJobs = jobList.slice(startIndex, endIndex);
    setCurrentDisplayedJobs(currentPageJobs);
  }, [currentPage, jobList]);



  const renderJobList = () => {
    return currentDisplayedJobs.map((job) => (
      <tr key={job.id}>
        <td className="w-jid">
          <a className="job-ref" href={`/viewjobdetails/${job.id}`}>
            <text>{job.id}</text>
          </a>
        </td>
        <td className="w-sname">{job.serviceId}</td>
        <td className="w-cost">{job.cost}</td>
        <td className="w-date" data-field-type="date">{job.date}</td>
        <td className="w-time">{job.time}</td>
      </tr>
    ));

  };


  const getTotalPages = () => Math.ceil(jobList.length / entriesPerPage);

  const renderPaginationLinks = () => {
    const totalPages = getTotalPages();
    const currentPageIndex = currentPage - 1;
    const paginationLinks = Array.from({ length: totalPages }, (_, index) => index + 1);
  
    // Determine the start and end index for the displayed page numbers
    let startPageIndex = Math.max(currentPageIndex - 2, 0);
    let endPageIndex = Math.min(startPageIndex + 4, totalPages - 1);
  
    // Ensure that 5 pages are always shown if possible
    if (endPageIndex - startPageIndex < 4) 
    {
      endPageIndex = Math.min(startPageIndex + 4, totalPages - 1);
      startPageIndex = Math.max(endPageIndex - 4, 0);
    }
  
    // Create the array of page numbers to display
    const visiblePaginationLinks = paginationLinks.slice(startPageIndex, endPageIndex + 1);
  
    return visiblePaginationLinks.map((page) => (
      <li key={page}>
        <a
          className={currentPage === page ? 'pageLink disabled' : 'pageLink'}
          onClick={() => handlePageChange(page)}
        >{page}
        </a>
      </li>
    ));
  };
  
  

  return (

    <div className="content">
      <Helmet>
        <title>Home Page</title>
      </Helmet>

{/* section 1 */}
      <div id="explore-services-container">
        <h1 className="home-center-heading white"> Explore Our Services </h1>

        <div className="row icons-container">
          <div className="col-lg-4">
            <a href="/utts">
              <img className="img-response" src={speech} alt="speech" />
              <div className="services-image-text"> SPEECH PROCESSING </div>
            </a>
          </div>

          <div className="col-lg-4">
            <a href="/pos_tagger">
              <img className="img-response" src={text} alt="text" />
              <div className="services-image-text"> TEXT PROCESSING </div>
            </a>
          </div>

          <div className="col-lg-4">
            <a href="/urdu_ocr">
              <img className="img-response" src={image} alt="image" />
              <div className="services-image-text"> IMAGE PROCESSING </div>
            </a>
          </div>
        </div>
      </div>



{/* section 2  */}
      <div className="container">
        <div className="panel-settings">
          <h2 className="home-center-heading">Usage History</h2>

          <table className="table">
            <thead>
              <tr>
                <th className="w-jid">JOB ID</th>
                <th className="w-sname">SERVICE NAME</th>
                <th className="w-cost">COST</th>
                <th className="w-date">DATE</th>
                <th className="w-time">TIME</th>
              </tr>
            </thead>

            <tbody>{renderJobList()}</tbody>
          </table>

          <div className="form-group col-md-11 pagination-centered">
            <ul className="pagination">

              <li>
                <a className="pageLink" onClick={() => handlePageChange(1)}> Newest </a>
              </li>

              <li>
                <a className="pageLink disabled">.</a> 
              </li>

              <li>
                <a className="pageLink disabled">.</a> 
              </li>

              <li>
                <a href="#" onClick={handlePreviousPage}>&lt;</a>
              </li>

              <li>
                <a className="pageLink disabled">.</a> 
              </li>

              {renderPaginationLinks()}

              <li>
                <a className="pageLink disabled">.</a> 
              </li>

              <li>
                <a href="#" onClick={handleNextPage}>&gt;</a>
              </li>

              <li>
                <a className="pageLink disabled">.</a> 
              </li>

              <li>
                <a className="pageLink disabled">.</a> 
              </li>

              <li>
                <a className="pageLink" onClick={() => handlePageChange(getTotalPages())}> Oldest </a>
              </li>

            </ul>
          </div>

        </div>
      </div>
      
    </div>
  );
}

const HomePageWithAuth = withAuth(Home);
export default HomePageWithAuth;
