import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/image_services.css';


export default function Image_services() {

  return (

<div> 

        <Helmet>
            <title>CLE Image Services</title>
        </Helmet>

        <div className="Page">
            <header className="Page-header">
                <div className="banner-title">CLE NLP WEBSERVICES </div>
                <div className="banner-tagline">BUILD APPLICATIONS NOW </div>
            </header>
        </div>


        <div className="row static-image_services-center-heading"> 
        Image Processing Services
        </div>

    <div className='image-services-content'>

        <div className="row image_services_title_box">
            <h2>
                <a href="/ocr" className='image_services_title'> 
                    Urdu Optical Character Recognition (OCR)
                </a>
            </h2>
            <div className="image_services_text">
                <p> CLE Urdu Nastalique Optical Character Recognition (OCR) service processes Urdu document images written in Noori Nastalique writing style having font size range from 14 to 44 and outputs Urdu text in Unicode format. The image should be scanned in grayscale with 300 DPI or 150 DPI having JPEG or BMP. This service will accelerate the process of publishing Urdu online content and make published Urdu material more accessible to illiterate and blind community.</p>
                <p>&nbsp;</p>
            </div>
        </div>


        {/* <div className="row image_services_title_box">
            <h2>
                <a href="/urdu_ocr" className='image_services_title'>
                    CAMERA CAPTURED ADDRESS RECOGNITION AND STRUCTURING (CCARS)
                </a>
            </h2>
            <div className="image_services_text">
                <p>CLE Camera Captured Address Recognition and Structuring (CCARS) service automatically recognizes camera captured image of Pakistani address printed in English. The image of the address is recognized and editable content is then converted into the structures fields including consignee name, phone number, city, area, address line 1.</p>
                <p>&nbsp;</p>
            </div>
        </div> */}

    </div>

    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  
</div>



  )
}


