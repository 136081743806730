import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/forgetPassword.css';

export default function ForgetPassword() {
  const [emailId, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleSubmitEmail = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/forget_password', {
      //const response = await fetch('http://192.168.1.121:8085/forget_password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailId }),
      });

      if (response.ok) {
        setResetSuccess(true);
        setErrorMessage("An email containing the change password link has been sent to your email account.");
      } else {
        const { message } = await response.json();
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="forget-page">
      <Helmet>
        <title>Forget Password</title>
      </Helmet>

        {resetSuccess ? (
          <div className='mail-send-msg'>
              <p>{errorMessage}</p>
          </div>
          
        ) : (
          <div className="row form-border-forget">
            <h2 className="text-center forget-heading">Forget Password ?</h2>
            <form onSubmit={handleSubmitEmail} className="form">
              <div className='reset-password-error-msg'>
                {errorMessage && <p>{errorMessage}</p>}
              </div>

              <div className="m-2">
                <input
                  type="email"
                  value={emailId}
                  className="form-control inputField-forget"
                  placeholder="Email address"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <button type="submit" className="btn btn buttonField-forget"> Reset Password </button>
            </form>
          </div>
       )}
    </div>
  );
}
