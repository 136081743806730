import React, { useState } from 'react';
import withAuth from '../users/withAuth';
import { Helmet } from 'react-helmet';
import '../styles/resetPassword_after_login.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function ResetPassword() {

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] =useState('');
  const [passwordAlertDisplayed, setPasswordAlertDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const emailId = localStorage.getItem('email');

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (newPassword === confirmPassword) {

      const user = {emailId, password, newPassword};

      try {                                       
          const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/reset_password', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(user)});
          //const response = await fetch('http://192.168.1.121:8085/reset_password', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(user)});
          if (response.ok) 
          {
            const Response = await response.json();
            const out = Response.message;
            setErrorMessage(out);
            window.location.href = "/home";
    
          } 
          else 
          {
            const Response = await response.json();
            const message = Response.message;
            setErrorMessage(message);
          }
    
        } catch (error) {
          setErrorMessage(error.message);
        }
    } 

    else {
      setErrorMessage('Password and confirm password do not match');
    }

  };




  const handlePasswordBlur = (event) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    const isValidPassword = passwordRegex.test(event.target.value);

    if (!isValidPassword) {
      alert('Please enter a password with at least 8 characters including at least one number, one letter, and one special character (!@#$%^&*)');
      setPasswordAlertDisplayed(true);
    }
  }

  return (

<div className='Page-for-reset'>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      
    <div className="first-box-reset-after-login p-2" style={{ paddingTop: '5%' }}>
      <div className="m-4 reset-container-well" align="center">
        <div className="alert alert-info">
          <a className="close" data-dismiss="alert">×</a>
          <text>You can change your password from here.</text>
        </div>

        <div className='error-message-reset'>
          {errorMessage && <p>{errorMessage}</p>}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group formating">

            <div className='row' style={{marginTop:'2%'}}>
              <label className="col-md-4 reset-password-label">Current Password</label>
                <div className="col-md-10 box-cover input-group"> 

                    <span className='input-group-addon'>
                      <i className="bi bi-person-fill" ></i>
                    </span>

                    <input 
                      type="password" 
                      placeholder="Current password" 
                      className="reset-password-box" 
                      required
                      value={password}
                      onBlur={handlePasswordBlur}
                      onChange={(event) => setPassword(event.target.value)}
                      /> 
                </div>

            </div>
                  
            <div style={{marginBottom:'2%'}} ></div>

            <div className='row'>
              <label className="col-md-4 reset-password-label-1">New Password</label>
                <div className="col-md-10 box-cover input-group"> 

                    <span className='input-group-addon'>
                      <i className="bi bi-person-fill" ></i>
                    </span>

                    <input 
                      type="password" 
                      placeholder="New password" 
                      className="reset-password-box" 
                      required
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      /> 
                </div>

            </div>

            <div style={{marginBottom:'2%'}} ></div>

            <div className='row'>
              <label className="col-md-4 reset-password-label">Confirm Password</label>
                <div className="col-md-10 box-cover input-group"> 

                    <span className='input-group-addon'>
                      <i className="bi bi-person-fill" ></i>
                    </span>

                    <input 
                      type="password" 
                      placeholder="Confirm password" 
                      className="reset-password-box" 
                      required
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      /> 
                </div>

            </div>

          </div>

          <button type='submit' className="btn btn-primary reset-password-button"> Change Password</button>

        </form>

      </div>
    </div>


</div>
  );
}

const ResetPasswordWithAuth = withAuth(ResetPassword);
export default ResetPasswordWithAuth;
