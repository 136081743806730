import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/ocr.css';
import '../styles/font_family.css';
import withAuth from '../users/withAuth';

const performanceOptions = [
  { value: '1', text: 'Fast' },
  { value: '0', text: 'Accurate' }
];

const fontOptions = Array.from({ length: 15 }, (_, index) => ({
  value: String(14 + index * 2),
  text: String(14 + index * 2),
}));

const depthOptions = Array.from({ length: 2 }, (_, index) => ({
  value: `${150 * (index + 1)} dpi`,
  text: `${150 * (index + 1)} dpi`,
}));

function OCR_Page() {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [outputText, setOutputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [performance, setPerformance] = useState('');
  const [fontSize, setFontSize] = useState('');
  const [dpi, setDpi] = useState('');
  const [loading, setLoading] = useState(false);
  const accessToken = localStorage.getItem('accesstoken');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setOutputText(''); // Clear output text
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setErrorMessage(''); // Clear any previous error messages when new image is uploaded
  
      const previewContainer = document.querySelector('.image-preview-container');
      const placeholder = document.querySelector('.image-placeholder');
  
      if (previewContainer) {
        previewContainer.classList.add('has-image');
      }
      
      if (placeholder) {
        placeholder.textContent = file.name;
      } else {
        console.error('Image placeholder element not found');
      }
    } else {
      const previewContainer = document.querySelector('.image-preview-container');
      if (previewContainer) {
        previewContainer.classList.remove('has-image');
      }
    }
  };
  
  

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setLoading(true); // Start loading state
  
    if (!image) {
      window.alert('Failed! Please upload an image.');
      setLoading(false);
      return;
    }
  
    try {
      const imgB64 = await convertImageToBase64(image); // Convert image to Base64
      const body = { 
        image: imgB64,
        accessToken,
        jobType: "Urdu OCR",
        performance,
        fontsize: fontSize,
        dpi_val: dpi.split(' ')[0] // Extract numeric part only
      };
  
      console.log("Request Body: ", body); // Log the request body
  
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE + '/ocr', {
      //const response = await fetch('http://192.168.1.121:8085/ocr', {

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
  
      if (!response.ok) {
        throw new Error('OCR Failed. Check CLE OCR Service.');
      }
  
      const result = await response.text(); // Parse the response as text
      console.log("Response: ", result); // Log the response
      // const cleanedResult = result
      // .replace(/\n/g, " ")    // Replace all newline characters with a space
      // .replace(/\s+/g, " ")   // Replace multiple spaces with a single space
      // .trim();                // Trim leading and trailing spaces      console.log("Cleaned Response: ", cleanedResult); // Log the response
      // setOutputText(cleanedResult); // Update the state with the OCR output

      setOutputText(result); // Update the state with the OCR output
      setErrorMessage(''); // Clear the error message when processing is successful
    } catch (error) {
      setErrorMessage(error.message); // Update error message
      console.error("Error: ", error); // Log any errors
    } finally {
      setLoading(false); // Stop loading state
    }
  };
  

  return (
    <div className="first-box-domain p-2">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner" />
        </div>
      )}

      <Helmet>
        <title>CLE URDU OPTICAL CHARACTER RECOGNITION </title>
      </Helmet>

      <div className="m-4 domain-container-well" align="center">
        <h3 className="text-center tts-heading">CLE Urdu Optical Character Recognition</h3>
        {errorMessage && <p className="stemmer-error-color">{errorMessage}</p>}

        <form onSubmit={handleSubmit}>
          <div className="col-md-3 domain-fields-format">
            <label className="left-label-domain">Performance:</label>

            <select value={performance} onChange={(e) => setPerformance(e.target.value)}>
              {/* <option value="" disabled>Select Performance</option> */}
              {performanceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>

            <label className="left-label-domain">Font:</label>

            <select value={fontSize} onChange={(e) => setFontSize(e.target.value)}>
              {/* <option value="" disabled>Select Font Size</option> */}
              {fontOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            {/* <select value={dpi} onChange={(e) => setDpi(e.target.value)}>
              <option value="" disabled>Select DPI</option>
              {depthOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select> */}

            <label className="left-label-domain">Resolution:</label>


            <select value={dpi} onChange={(e) => setDpi(e.target.value)}>
              {/* <option value="" disabled>Select DPI</option> */}
              <option value="300 dpi">300 DPI</option> {/* You can adjust this option for now */}
            </select>
          </div>

          <div className="flex-container-horizontal">
            <div className="box-container">
              <div className="input-container">
                <label className="form-label domain-label">Input Image</label>
                <input 
                  type="file"
                  id="imageUpload"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*" 

                />
                <div className="image-preview-container">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Selected" className="image-preview" />
                  ) : (
                    <div className="image-placeholder">No image selected</div>
                  )}
                </div>
                <div className="button-container">
                  <button 
                    type="button" 
                    className="btn domain-button" 
                    onClick={() => document.getElementById('imageUpload').click()}
                  >
                    Upload
                  </button>
                  <button 
                    type="submit" 
                    className="btn domain-button"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="box-container">
              <div className="output-container">
                <label className="form-label domain-label">Output Text</label>
                <textarea
                  type="text"
                  className="form-control domain-textarea urduFont"
                  value={outputText}
                  readOnly
                />
                <div className="button-container">
                  <button 
                    type="button" 
                    className="btn domain-button" 
                    onClick={() => navigator.clipboard.writeText(outputText)}
                  >
                    Copy
                  </button>
                  <Link className="btn domain-button" to="/">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div style={{ paddingBottom: '5%' }}></div>
      </div>
    </div>
  );
}

const OCR_PageWithAuth = withAuth(OCR_Page);
export default OCR_PageWithAuth;
