import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_ocr.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function OCR() {

  return (


    <div id="content-ocr"> 

        <Helmet>
            <title>CLE Optical Character Recognition (OCR) Service</title>
        </Helmet>

        <div className="row ocr-banner">
                <div className="ocr-banner-title"> URDU OPTICAL CHARACTER RECOGNITION (OCR) SERVICE </div>
        </div>


        <div className="row ocr-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="ocr-service-text">
                        <p>CLE Urdu Nastalique Optical Character Recognition (OCR) service processes Urdu document 
                            images written in Noori Nastalique writing style having font size range from 14 to 44 and 
                            outputs Urdu text in Unicode format. The image should be scanned in grayscale with 300 DPI 
                            or 150 DPI having JPEG or BMP. This service will accelerate the process of publishing Urdu
                            online content and make published Urdu material more accessible.</p>  
                    </div>
                </div>
                
                <div className="row">
                    <a href="/register" className="ocr-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_ocr">
                    <div className="ocr-api-ref">
                        <img src={tts_api} alt='ocr api'/>
                        <div className="ocr-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}
