import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/pricing.css';

import price_icon from '../icons/pricing/warning-icon.png';


export default function Pricing() {


    return (

<div> 

            <Helmet>
                <title>Pricing</title>
            </Helmet>


            {/* section 1 */}
            <div className="Page-pricing">       
                    <div class="row" align="center">
                        <div class="col-lg-12" align="center">
                            <div class="banner-title-pricing">CLE NLP WEBSERVICES </div>
                            <div class="banner-tagline-pricing">BUILD APPLICATIONS NOW </div>
                        </div>
                    </div>
            </div>




            {/* section 2 */}
            <div className="col-md-12">
                <h2 className="center-heading-pricing">Pricing details*</h2>
            </div>

            <div>
                <div className="box-size">
                    <p className='box-text'>
                        Unlimited free access for testing our services through web interface. The below mentioned prices will be charged when use through the developer interface.
                    </p>
                </div>
            </div> 

            <br></br>




                {/* section 3 */}
                    <div className="col-md-8">
                        <table className="table-price">
                            <tbody>

                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Urdu Text-to-Speech
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.01 per character for a transaction over 100 characters</p> 
                                        <p>PKR 1.00 per transaction for characters less than 100</p>  
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Punjabi Text-to-Speech
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.01 per character for a transaction over 100 characters</p> 
                                        <p>PKR 1.00 per transaction for characters less than 100</p>  
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Urdu Part-of-Speech
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">                 
                                        <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                                        <p>PKR 0.25 per transaction for words less than 500</p>  
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Profanity Estimation
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.01 per 100 characters for a transaction over 500 characters</p>
                                        <p>PKR 0.05 per transaction for characters less than 500 characters</p>  
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Language Identification
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.01 per 100 characters for a transaction over 500 characters</p>
                                        <p>PKR 0.05 per transaction for characters less than 500 characters</p> 
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Text Summarization
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.001 per character for a transaction over 1000 characters</p>
                                        <p>PKR 1.0 per transaction for characters less than 1000 characters</p>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Domain Identification
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.001 per character for a transaction over 1000 characters</p>
                                        <p>PKR 1.0 per transaction for characters less than 1000 characters</p>  
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Roman to Urdu Script
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 1.0 per request</p>
                                        <br></br>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Spell Checking
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.01 per 100 characters for a transaction over 500 characters</p>
                                        <p>PKR 0.05 per transaction for characters less than 500 characters</p> 
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Keyword Extraction
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                                        <p>PKR 0.25 per transaction for words less than 500</p>   
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Urdu Speech to Text
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.75 per 15 seconds</p>
                                        <p>Each request is rounded up to the nearest increment of 15 seconds</p>
                                        <br></br>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Urdu Stemmer
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                                        <p>PKR 0.25 per transaction for words less than 500</p> 
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <p class="service-name">
                                            Urdu OCR
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.5 for an image file of size less than or equal to 2MB</p>
                                        <p>PKR 0.25 per 1MB for an image file of size greater than 2MB</p>                       
                                        <br></br>
                                        <span class="table-divider"></span>
                                    </td>
                                </tr>


                                {/* <tr>
                                    <td>
                                        <p class="service-name">
                                            Speech-to-Speech
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 5.0 per request</p>
                                        <br></br>
                                    </td>
                                </tr> */}


                                {/* <tr>
                                    <td>
                                        <p class="service-name">
                                            Camera Captured Address Recognition and Structuring
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>0.05 PKR for image less than or equal to 50Kb</p>
                                        <p>0.01 PKR per 10KB for image greater than 50Kb</p>
                                        <br></br>
                                    </td>
                                </tr> */}

                                
                               
                                

                                {/* <tr>
                                    <td>
                                        <p class="service-name">
                                            Urdu Segmentation
                                        </p>
                                    </td>
                                    <td class="service-price-detail" height="100">
                                        <p>PKR 0.05 per 100 words for a transaction over 500 words</p>
                                        <p>PKR 0.25 per transaction for words less than 500</p>  
                                    </td>
                                </tr> */}
                                
                                <tr></tr>
                            </tbody>
                        </table>
                    </div>



            {/* section 4 */}
            <div id="payment">
                <h2 class="center-heading-payment"> Payment Procedure </h2>
                <div className='payment-row'>

                    <div class="col-lg-4 payment-column-1">
                        <div class="dot">
                            <p>1</p>
                        </div>
                        <div class="payment-column-text">
                            Fill in the&nbsp;
                            <a class="cle-blue" href="http://cle.org.pk/cletech/sof.php">
                                service order form
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-4 payment-column-2">
                        <div class="dot">
                            <p>2</p>
                        </div>
                        <div class="payment-column-text">
                            Email us the duly filled in service order form and scanned bank payment receipt at&nbsp;
                            <a class="cle-blue" href="#">cletech@cle.org.pk</a>
                        </div>
                    </div>

                    <div class="col-lg-4 payment-column-3">
                        <div class="dot">
                            <p>3</p>
                        </div>
                        <div class="payment-column-text">
                            As soon as payment is received, CLE will add credit into your account
                        </div>
                    </div>
                </div>



                <div className="row payment-row">
                    <div className="col-lg-12 payment-column">
                        <p className="footnote">
                            <span>
                                <img src={price_icon} alt="image"> 
                                </img> 
                                &nbsp;&nbsp;NOTE: The user account balance must be used within 1 year of last deposit
                            </span>
                        </p>
                        <p className="footnote" style={{marginBottom: "2%"}}>
                            *CLE Reserves the right to change this pricing plan at any time and without notice.
                        </p>
                    </div>
                </div>
            </div>
</div>
      
    )
}
