import React, { useRef, useState, useEffect} from 'react';
import io from 'socket.io-client';
import { Helmet } from 'react-helmet';
import recorderWorker from './recorderWorker';
import withAuth from '../users/withAuth';
import '../styles/asr.css';
import '../styles/font_family.css';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}


var Recorder = function(source, cfg)
{
  console.log(source);
  var config = cfg || {};
  var bufferLen = 4096;
  this.context = source.context;
  this.node = this.context.createScriptProcessor(bufferLen, 1, 1);
  var worker = new Worker(URL.createObjectURL(new Blob([`(${recorderWorker})()`])));
  worker.postMessage("DATA")
  worker.postMessage({
    command: 'init',
    config: {
      sampleRate: this.context.sampleRate
    }
  });
  var recording = false,
    currCallback;

  this.node.onaudioprocess = function(e){
    if (!recording) return;
    worker.postMessage({
      command: 'record',
      buffer: [
        e.inputBuffer.getChannelData(0)
      ]
    });
  }

  this.configure = function(cfg){
    for (var prop in cfg){
      if (cfg.hasOwnProperty(prop)){
        config[prop] = cfg[prop];
      }
    }
  }

  this.record = function(){
    recording = true;
  }

  this.stop = function(){
    recording = false;
  }

  this.clear = function(){
    worker.postMessage({ command: 'clear' });
  }

  this.getBuffer = function(cb) {
    currCallback = cb || config.callback;
    worker.postMessage({ command: 'getBuffer' })
  }

  this.exportWAV = function(cb, type){
    currCallback = cb || config.callback;
    type = type || config.type || 'audio/wav';
    if (!currCallback) throw new Error('Callback not set');
    worker.postMessage({
      command: 'exportWAV',
      type: type
    });
  }

  this.exportRAW = function(cb, type){
    currCallback = cb || config.callback;
    type = type || config.type || 'audio/raw';
    if (!currCallback) throw new Error('Callback not set');
    worker.postMessage({
      command: 'exportRAW',
      type: type
    });
  }

  this.export16kMono = function(cb, type)
  {
    // console.log("export16kMono Called");
    currCallback = cb || config.callback;
    type = type || config.type || 'audio/raw';
    if (!currCallback) throw new Error('Callback not set');
    worker.postMessage({
      command: 'export16kMono',
      type: type
    });

  }

  worker.onmessage = function(e){
    var blob = e.data;
    currCallback(blob);
  }

  source.connect(this.node);
  this.node.connect(this.context.destination);    //TODO: this should not be necessary (try to remove it)
};




function AudioStream () {
  
  const socketRef = useRef();
  const recRef = useRef();
  var gumStream;
  var intervalId;
  
  const accessToken = localStorage.getItem('accesstoken');

  var trueResult = '';
  Storage.finalResult = '';


  const [startButton, setStartButton] = useState(false);
  const [stopButton, setStopButton] = useState(true);
  const [clearButton, setClearButton] = useState(true);
  const [slots, setSlots] = useState(0);    




  const connectSocket = () => {
    // const socket = io.connect(process.env.REACT_APP_ASR_SOCKET, {   
  const socket = io.connect('http://127.0.0.1:3434', {  
      transportOptions: {
        polling: {
          extraHeaders: {
            connectionid: accessToken,
          },
        },
      },
    });
    socketRef.current = socket;
  };





  const free_workers = async () => {
    // const response = await fetch(process.env.REACT_APP_ASR_SOCKET+'/free_asr_workers', { method: 'POST'});
    const response = await fetch('http://127.0.0.1:3434/free_asr_workers', { method: 'POST'});
    if (response.ok) 
    {
      const data = await response.json();
      return data.free_asrWorkers;
    }
  }



  useEffect (() => {
    (async () => {
      const fetchedSlots = await free_workers();
      setSlots(fetchedSlots);
    })();

  }, []);






  const handleStart = async () => {

    try {

      connectSocket();

      const asr = await free_workers();
      setSlots(asr-1);
      console.log("start asr: ", asr-1);
      var textArea = document.getElementById('transcription');

      socketRef.current.on("response", function(msg) {
        console.log("transcription ", msg.response.result.hypotheses[0].transcript);
  
        if (msg.response.result.final)
        {
            trueResult = msg.response.result.hypotheses[0].transcript
            textArea.value = Storage.finalResult + msg.response.result.hypotheses[0].transcript
            Storage.finalResult += trueResult
            console.log("text area 1 : ", textArea.value);
        }
        else
        {
            textArea.value =  Storage.finalResult + msg.response.result.hypotheses[0].transcript + '\n'
            console.log("text area 2 : ", textArea.value);
        }
    });



      const handleSuccess = (stream) => {
      var audioContext = new (window.AudioContext || window.webkitAudioContext)();
      gumStream = stream;

      var input = audioContext.createMediaStreamSource(stream);

      var rec = new Recorder(input,{ workerPath : './recorderWorker.js' })
      recRef.current = rec;

      intervalId = setInterval(function()
        {
          recRef.current.export16kMono(function(blob) {
            socketRef.current.emit('audio_bytes', blob);
            recRef.current.clear();
          }, 'audio/x-raw');
        }, 1000)

      recRef.current.record()
      console.log("Recording started");
      }

      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(handleSuccess);

      setStartButton(true);
      setStopButton(false);
      setClearButton(true);

    } 
    catch (error) 
    {
      console.error(error);
    }

  };







  const handleStop = async () => {
     
    console.log("inside handle stop");
    sleep(1000);
  
    recRef.current.stop();
    recRef.current.clear();
    clearInterval(intervalId); 

    socketRef.current.emit('audio_end');
    socketRef.current.disconnect();

    if (gumStream) {
      gumStream.getTracks().forEach((track) => track.stop()); // Stop the media stream
    }

    sleep(2000);

    const asr = await free_workers();
    setSlots(asr+1);
    console.log("stop asr: ", asr+1);

    // window.location.reload();
    setStartButton(false);
    setStopButton(true);
    setClearButton(false);

  };




  const handleClear = () => {
  var textArea = document.getElementById('transcription');
    textArea.value = '';
  };



  return (
<div className="first-box-asr p-2">
  <Helmet>
    <title>CLE Urdu ASR</title>
  </Helmet>

  <div className='left-services'>
    <a href='/female_tts'>
      Urdu Text-to-Speech
    </a>
  </div>

  {/* <div className='left-services'>
    <a href=''>
      Speech-to-Speech
    </a>
  </div> */}

  <div className="m-4 container-well" align='center'>
    <div className='col-md-12'>
    <h3 className="text-center tts-heading">Urdu Speech-to-Text</h3>
      <div className="col-md-9 flex-container-button">
        <textarea id='transcription' class="urduFont" readOnly></textarea>
        
        <div style={{padding:'1%'}}></div>

        <div className='btn-center middle-btn-asr'>
          <button className="btn btn asr-button" onClick={() => { handleStart();}} disabled={startButton}>Start</button>
          <button className="btn btn asr-button" onClick={() => { handleStop(); }} disabled={stopButton}>Stop</button>
          <button className="btn btn asr-button" onClick={() => { handleClear(); }} disabled={clearButton}>Clear</button>
        </div>

        <span style={{paddingLeft:'15px', fontFamily:'Raleway'}}>
          <text id="serverStatusBar">Currently available slots: {slots}</text>
        </span>
        
      </div>
    </div>
  </div>
  <div style={{paddingBottom:'5%'}}> </div>
</div>

    );
}

// export default AudioStream;

const AudioStreamWithAuth = withAuth(AudioStream);
export default AudioStreamWithAuth;