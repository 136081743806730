import React from 'react'
import { Nav , NavDropdown } from 'react-bootstrap';
import { useLocation,} from 'react-router-dom';

import logo from '../icons/navbar/cle.png';
import white_profile_pic from '../icons/navbar/profile-logo.png';

import '../styles/white_navbar.css';
import { useEffect } from 'react';

export default function White_NavBar() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offset: -1000 });
      }
    }
  }, [location]);



  return (

    
  <nav className="navbar navbar-expand-lg navbar-line navbar-light" style={{marginBottom:'1%'}}>

          <a href="/landingPage" className="navbar-brand" style= {{ marginLeft: '15%'}}>
            <img className='logo-pic' src={logo} alt='logo'></img>
            <p className='text-of-logo' >Center For Language Engineering</p>
          </a>

          <a style= {{ marginLeft: '19%'}}>

            <li className="nav-item dropdown profile-pic-white">
              <button className="btn" data-bs-toggle="dropdown">
                <img src={white_profile_pic} alt="Profile pic" />
              </button>

              <ul className="dropdown-menu drop-menu">
                <li><a href='/loginpage' className='dropdown-text'>Sign in</a></li>
                <li><a href="/register" className='dropdown-text'>Register</a></li>
              </ul>
            </li>

            <button style={{fontFamily:"Raleway"}} className="navbar-toggler menu-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span>Menu</span>
            </button>


            <div className="collapse navbar-collapse menu-class-drop" id="collapsibleNavbar">
                <ul className="navbar-nav ms-auto">

                  <li className="nav-item">
                    <Nav.Link href="/landingPage" className='black white-cyan-bar'>HOME</Nav.Link>
                  </li>


                  <div className="dropdown">
                    <Nav.Link href="/landingPage#for-services" className='black white-cyan-bar' >SERVICES &#9660;</Nav.Link>
                    <div className="dropdown-content">
                      <div className="service-table">
                        <div className="row">
                          <div className="column">
                            <h3>SPEECH PROCESSING</h3>
                            <ul>
                            <li><a href="/tts">URDU TEXT-TO-SPEECH</a></li>  
                              <li><a href="/stt">URDU SPEECH-TO-TEXT</a></li>
                              <li><a href="/ptts">PUNJABI TEXT-TO-SPEECH</a></li>
                              {/* <li><a href="/subOption3">SPEECH-TO-SPEECH</a></li> */}
                            </ul>
                          </div>
                          <div className="column">
                            <h3>TEXT PROCESSING</h3>
                            <ul>
                              <li><a href="/postagger">URDU PARTS-OF-SPEECH</a></li>
                              <li><a href="/profanity">URDU CONTENT PROFANITY ESTIMATION</a></li>
                              <li><a href="/langid">URDU LANGUAGE IDENTIFICATION</a></li> 
                              <li><a href="/textsummerization">URDU TEXT SUMMARIZATION</a></li>
                              <li><a href="/domainidentification">URDU DOMAIN IDENTIFICATION</a></li>
                              <li><a href="/spell">URDU SPELL CHECKING</a></li>
                              <li><a href="/keywordextraction">URDU KEYWORD EXTRACTION</a></li>
                              {/* <li><a href="/roman">ROMAN TO URDU SCRIPT</a></li> */}
                              <li><a href="/stemmer">URDU STEMMER</a></li>
                              {/* <li><a href="/subOption5">ASPECT BASED SENTIMENT ANALYSIS</a></li>
                              <li><a href="/subOption5">URDU SEGMENTATION</a></li> */}
                            </ul>
                          </div>
                          <div className="column">
                            <h3>IMAGE PROCESSING</h3>
                            <ul>
                              <li><a href="/ocr">URDU OPTICAL CHARACTER RECOGNITION</a></li>
                              {/* <li><a href="/subOption8">CAMERA CAPTURED ADDRESS RECOGNITION AND STRUCTURING</a></li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <li className="nav-item">
                  <Nav.Link href="/pricing" className='black white-cyan-bar'>PRICING</Nav.Link>
                  </li>

                  <li className="nav-item">
                  <Nav.Link href="/landingPage#fellowship-award" className='black white-cyan-bar'>FELLOWSHIP AWARD</Nav.Link>
                  </li>

                  <li className="nav-item">
                  <Nav.Link href="/landingPage#get-started-container" className='get-started black white-cyan-bar'> GET STARTED</Nav.Link>
                  </li>

                </ul>
            </div>

          </a>

  </nav> 



  );

}
