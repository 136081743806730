import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../styles/signin.css';

export default function LoginPage() {

  const [userEntity, setUserEntity] = useState({});
  const [errorMessage, setErrorMessage] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {   
      // const myurl =  process.env.REACT_APP_TECH_SERVICE
      
      // console.log("This is url =  ${process.env.REACT_APP_TECH_SERVICE}/tech_login ",myurl)
      
                     
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/tech_login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userEntity)});
      //const response = await fetch('http://192.168.1.121:8085/tech_login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userEntity)});
      
      // const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/tech_login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userEntity)});
      

      if (response.ok) 
      {
        setErrorMessage('');
        const loginResponse = await response.json();
        localStorage.setItem('email', loginResponse.emailId); 
        localStorage.setItem('accesstoken', loginResponse.accessToken);
        localStorage.setItem('isLogin', loginResponse.isLogin);
        localStorage.setItem('firstName', loginResponse.firstName);
        localStorage.setItem('balance', loginResponse.balance);
        window.location.replace('/');
      } 
      else 
      {
        const loginResponse = await response.json();
        setErrorMessage(loginResponse.message);
      }

    } catch (error) {
      console.log("inside catch error: "+ error);
      setErrorMessage(error.message);
    }
  };



  return (

<div className="Page-sign-in">
    <Helmet>
        <title>Sign in</title>
    </Helmet>

      <h1 className="sign-in">Sign in</h1>
      { errorMessage && <p className='login-error-clr '>{errorMessage}</p>}

        <form onSubmit={handleSubmit} className='form form-border-sign' >
          <div className="m-2">
            <input
              type="text"
              className="form-control inputField-sign"
              placeholder="Email*"
              onChange={(event) => setUserEntity({ ...userEntity, emailId: event.target.value })}
            />
          </div>
          <div className="m-2">
            <input
              type="password"
              className="form-control inputField-sign"
              placeholder="Password*"
              onChange={(event) => setUserEntity({ ...userEntity, password: event.target.value })}
            />
          </div>
          <button type="submit" className="btn btn buttonField-sign" onClick={handleSubmit}>
            <div style={{marginTop:'2%', marginBottom:'2%'}}> Sign in</div>
          </button>

        <div className='links-sign-in'>
          <Link className='links-sign-1' to='/forgetPassword'>Forget Password ?</Link>
          <Link className='links-sign-2' to='/register'>Create New Account ?</Link>
        </div>
    </form>

</div>

  );
}