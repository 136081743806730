import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import '../styles/resetPassword.css';

export default function ResetPassword() {

  const [emailId, setEmailId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetSuccess1, setResetSuccess1] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/forget_password_token', {  
      //const response = await fetch('http://192.168.1.121:8085/forget_password_token', { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword, token , emailId}),
      });

      if (response.ok) {
        setResetSuccess1(true);
        setErrorMessage("Your password has been changed.");
      } else {
        const { message } = await response.json();
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (

  <div className="reset-page">
    <Helmet>
        <title>Reset Password</title>
    </Helmet>
    
    {resetSuccess1 ? (
      <div className='reset-password-msg'>
          <p>{errorMessage}</p>
      </div>

      ) : (

      <div className="row form-border-reset" >
        <h2 className="text-center reset-heading">Reset Password</h2>
        <div className='reset-password-error-msg'>
          {errorMessage && <p>{errorMessage}</p>}
        </div>

        <form onSubmit={handleSubmit} className='form'>
          <div className="m-2">
            <input 
              className="form-control inputField-reset"
              placeholder="New Password*"
              type="password" 
              value={newPassword} 
              required
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </div>
          <div className="m-2">
            <input 
              className="form-control inputField-reset"
              placeholder="Token*"
              required
              type="text" value={token} onChange={(event) => setToken(event.target.value)} 
            />
          </div>
          <div className="m-2">
            <input 
              className="form-control inputField-reset"
              placeholder="Email*"
              required
              type="text" value={emailId} onChange={(event) => setEmailId(event.target.value)}
            />
          </div>
          <button type="submit" className="btn btn buttonField-reset">Reset Password</button>
        </form>
      </div>
      )}

  </div>
  );
}
