import React, { useEffect, useState }  from 'react'
import { Nav } from 'react-bootstrap';

import logo from '../icons/navbar/cle-markaz-white.png';
import '../styles/services_navbar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';



export default function ASR_NavBar() {

  const email = localStorage.getItem('email');
  var isLogin = localStorage.getItem('isLogin');
  const first_name = localStorage.getItem('firstName');
  var [balance, setBalance] = useState(null);

  const fetchBalance = async () => {
    try {
      const balanceResponse = await fetch(process.env.REACT_APP_TECH_SERVICE+'/check_balance', {
      // const balanceResponse = await fetch('http://localhost:8085/check_balance', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailId: localStorage.getItem('email') })
      });
  
      if (balanceResponse.ok) {
        const balanceData = await balanceResponse.json();
        setBalance(balanceData);
        localStorage.setItem('balance', balanceData);
      } else {
        setBalance(null);
      }
    } catch (error) {
      setBalance(null);
    }
  };
  
  useEffect(() => {
    fetchBalance();
  }, []);

  balance = localStorage.getItem('balance');


  const handleSubmit1 = (event) => {  
    isLogin = false;
    localStorage.removeItem('email');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('firstName');
    localStorage.removeItem('balance');
    localStorage.removeItem('accesstoken');
    window.location.replace('/landingPage');
};



  return (

<div>

{isLogin ? (

<nav className="navbar navbar-expand-lg navbar-light black-bg">

      <div style= {{ marginLeft: '15%', marginBottom:'1%'}}>
        <a href="/home" className="navbar-brand" >
            <img id="logo" className='black-logo-pic' src={logo} alt='logo'></img>
            <p className='black-text-of-logo' >Center For Language Engineering</p>
        </a>

        <p id="balance-text" className='white'>
            <b>Current Balance: <text>{balance} PKR</text></b>
        </p>
      </div>

      <a style= {{ marginLeft: '20%', marginBottom:'2%'}} className='black-navbar-menu-center'>

        <button style={{fontFamily:"Raleway"}} className="navbar-toggler white menu-class-black-dash" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span>Menu</span>
        </button>


        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ms-auto">

            <li className="nav-item">
              <Nav.Link href="/home" className='white-color cyan-bar' style={{fontWeight:"bold"}}>MY DASHBOARD</Nav.Link>
            </li>


            <div className="dropdown black-bg cyan-bar" style={{background:'black'}}>
              <button className="dropbtn-b" style={{fontWeight:"bold"}}>SERVICES &#9660; </button>
              <div className="dropdown-content">
                <div className="service-table">
                  <div className="row">
                    <div className="column">
                      <h3>Speech Processing</h3>
                      <ul>
                        <li><a href="/female_tts" style={{color:'black'}}>Urdu Text-to-Speech</a></li>
                        
                        <li><a href="/punjabi_male_tts" style={{color:'black'}}>Punjabi Text-to-Speech</a></li>
                        <li><a href="/asr" style={{color:'black'}}>Urdu Speech-to-Text</a></li>
                        {/* <li><a href="/subOption3" style={{color:'black'}}>Speech-to-Speech</a></li> */}
                      </ul>
                    </div>
                    <div className="column">
                      <h3>Text Processing</h3>
                      <ul>
                        <li><a href="/pos_tagger" style={{color:'black'}}>Urdu Parts-of-Speech</a></li>
                        <li><a href="/Profanity_estimation" style={{color:'black'}}>Urdu Content Profanity Estimation</a></li>
                        <li><a href="/Language_identification" style={{color:'black'}}>Urdu Langauge Identification</a></li> 
                        <li><a href="/Text_Summerization" style={{color:'black'}}>Urdu Text Summarization</a></li>
                        <li><a href="/Domain_Identification" style={{color:'black'}}>Urdu Domain Identification</a></li>
                        <li><a href="/Spell_Checker" style={{color:'black'}}>Urdu Spell Checking</a></li>
                        <li><a href="/Keyword_Extraction" style={{color:'black'}}>Urdu Keyword Extraction</a></li>
                        {/* <li><a href="/Urdu_romanization" style={{color:'black'}}>Roman to Urdu Script</a></li> */}
                        <li><a href="/Urdu_stemmer" style={{color:'black'}}>Urdu Stemmer</a></li>
                        {/* <li><a href="/subOption5" style={{color:'black'}}>ASPECT BASED SENTIMENT ANALYSIS</a></li>
                        <li><a href="/subOption5" style={{color:'black'}}>Urdu SEGMENTATION</a></li> */}
                      </ul>
                    </div>
                    <div className="column">
                      <h3>Image Processing</h3>
                      <ul>
                      <li><a href="/urdu_ocr" style={{color:'black'}}>Urdu Optical Character Recognition</a></li>

                        {/* <li><a href="/subOption7" style={{color:'black'}}>Urdu OPTICAL CHARACTER RECOGNITION</a></li>
                        <li><a href="/subOption8" style={{color:'black'}}>CAMERA CAPTURED ADDRESS RECOGNITION AND STRUCTURING</a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <li className="nav-item">
            <Nav.Link href="/pricing_login" className='white-color cyan-bar' style={{fontWeight:"bold"}}>PRICING</Nav.Link>
            </li>

            <li className="nav-item">
            <Nav.Link href="/api_asr" className='white-color cyan-bar' style={{fontWeight:"bold"}}>DEVELOPERS</Nav.Link>
            </li>



            <li className="nav-item dropdown dash-black">
              <button className="btn btn dashboard-get-started" data-bs-toggle="dropdown" style={{fontWeight:"bold"}}> 
                <i className="bi bi-person-fill"></i>
                <text> {first_name} </text> 
                <span> &#9660; </span> 
              </button>


              <ul className="dropdown-menu drop-menu blackish-bg-white-text">
                <li><a href="/access_token" className='dropdown-text-dashboard'>Access token</a></li>
                <li><a href="/user_info" className='dropdown-text-dashboard'>Personal Info</a></li>
                <li><a href="/reset_password" className='dropdown-text-dashboard'>Change password</a></li>
                <li><hr className='dropdown-divider' /></li>

                <Nav.Link className='dropdown-text-dashboard dash-link' onClick={handleSubmit1} style={{marginLeft:'6%'}} >Logout</Nav.Link>
              </ul>
            </li>

          </ul>
        </div>
      </a>
</nav> 

):(

<div></div>

)}
</div>

  );

}
