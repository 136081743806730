import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/api_profanity.css';


function Static_API_OCR() {
  

  return (

<div>
    <Helmet>
      <title>Urdu OCR API Documentation</title>
    </Helmet>

    <div className="row static-profanity-center-heading"> 
    URDU OPTICAL CHARACTER RECOGNITION (OCR) API REFERENCE
    </div>


{/* python code snippets */}
  <div className="first-box-api_profanity p-2" >
    <div className='api-tts-code-heading'>Python</div>
    <div className='api_profanity_text'>
      This section presents code snippets for using CLE OCR API with Python.
      <br/> <br/>
      1. Following are the required imports that will be used.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
          {`
    import requests
    import json
    import base64`}
      </pre>
    </div>

    <div className='api_profanity_text'>
      2. The Urdu OCR web service takes following input arguments.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
          {`

      url = "https://api.cle.org.pk/ocr"
      file_name = "Your Image Path"
      accessToken = "Your accessToken" 
      dpi_val = "Enter dpi value"             # 150 or 300
      performance = "Enter performance value" # 0 (Accurate) or 1 (Fast)
      fontsize = "Enter font size"            # 14 to 42 

      with open(file_name, 'rb') as f:
        file_data = f.read()

     payload = json.dumps({
      "image": base64.b64encode(file_data).decode('utf-8'),
      "accessToken": accessToken,
      "dpi_val": dpi_val,
      "performance": performance,
      "fontsize": fontsize
    })`}
      </pre>
    </div>

    <div className='api_profanity_text'>
      3. Use Python client for connecting to the web service and returing the output as shown below.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
        {`
    headers = {'Content-Type': 'application/json'}
    response = requests.request("POST", url, headers=headers, data=payload)
    print(response.text)`}
      </pre>
    </div>

    <div className='api_profanity_text'>
      4. The method will return a response message. This message has 2 things.
        <br/>
        (i) Result : It gives the status of the output of the task.
        <br/>
        (ii) Response : It gives the text of the provided Image.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
        {`
    { "result" : "Status of the performed task/job",
    "response" : "Output"   }                  `}
      </pre>
    </div>
    <div className='api_profanity_text'>
    5. Use following code to get text from the response entity.

    </div>

    <div className="m-4 api_profanity-container-well">

      <pre>
    {`
    if response.status_code == 200:
        response_data = response.json()
        ocr_text = response_data.get('response', 'OCR is not working.')
        print("Extracted OCR Text:")
        print(ocr_text)
    else:
        print(f"Error: {response.status_code} - {response.reason}") `}
      </pre>
    </div>


  </div>


{/* java code snippets */}
<div className="first-box-api_profanity p-2" >
    <div className='api-tts-code-heading'>Java</div>
    <div className='api_profanity_text'>
      This section presents code snippets for using CLE Urdu OCR API with Java.
      <br/> <br/>
      1. Imports that you require for java code.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
          {`
    import java.net.URI;
    import java.net.http.HttpClient;
    import java.net.http.HttpRequest;
    import java.net.http.HttpResponse;
    import java.nio.file.Files;
    import java.nio.file.Paths;
    import java.util.Base64;
    import org.json.JSONObject; `}
      </pre>
    </div>

    <div className='api_profanity_text'>
    2. The Urdu OCR method of CLE Urdu OCR web service takes following arguments.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
          {`
    String url = "https://api.cle.org.pk/ocr";
    String accessToken = "<<your access token>>";
    String fileName = "<<your file path>>";
    String dpiVal = "<<your dpi value>>";               // 150 or 300
    String performance = "<<your performance value>>";  // 0 (Accurate) or 1 (Fast)
    String fontsize = "<<your font size>>";             // 14 to 42 `}
      </pre>
    </div>

    <div className='api_profanity_text'>
      3. Whole code should look like this.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
        {`
    import java.net.URI;
    import java.net.http.HttpClient;
    import java.net.http.HttpRequest;
    import java.net.http.HttpResponse;
    import java.nio.file.Files;
    import java.nio.file.Paths;
    import java.util.Base64;
    import org.json.JSONObject;


    public class OCRClient {

        public static void main(String[] args) throws Exception {

            // Define your parameters
            String url = "https://api.cle.org.pk/ocr";
            String accessToken = "<<your access token>>";
            String fileName = "<<your file path>>";
            String dpiVal = "<<your dpi value>>";               // 150 or 300
            String performance = "<<your performance value>>";  // 0 (Accurate) or 1 (Fast)
            String fontsize = "<<your font size>>";             // 14 to 42

            // Read the image file in binary mode
            byte[] fileData = Files.readAllBytes(Paths.get(fileName));

            // Encode image in Base64
            String encodedImage = Base64.getEncoder().encodeToString(fileData);

            // Prepare JSON payload
            JSONObject payload = new JSONObject();
            payload.put("image", encodedImage);
            payload.put("accessToken", accessToken);
            payload.put("dpi_val", dpiVal);
            payload.put("performance", performance);
            payload.put("fontsize", fontsize);

            // Define HTTP client
            HttpClient client = HttpClient.newHttpClient();

            // Create HTTP request
            HttpRequest request = HttpRequest.newBuilder()
                    .uri(URI.create(url))
                    .header("Content-Type", "application/json")
                    .POST(HttpRequest.BodyPublishers.ofString(payload.toString()))
                    .build();

            // Send the request
            HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());

            // Check if response is successful
            if (response.statusCode() == 200) {
                // Parse the response JSON
                JSONObject jsonResponse = new JSONObject(response.body());

                // Extract the OCR text
                String ocrText = jsonResponse.optString("response", "No OCR text found");

                // Print extracted OCR text
                System.out.println("Extracted OCR Text:");
                System.out.println(ocrText);
            } else {
                // Handle the error response
                System.out.println("Error: " + response.statusCode() + " - " + response.body());
            }

        }
    }
`}
      </pre>
    </div>

    <div className='api_profanity_text'>
      4. The method will return a  response message. This json message has 2 things.
        <br/>
        (i) Result : It gives the status of the output of the task.
        <br/>
        (ii) Response : It gives the text of the provided image.
    </div>

    <div className="m-4 api_profanity-container-well">
      <pre>
        {`
    { "result" : "Status of the performed task/job",
    "response" : "Output"   }                  `}
      </pre>
    </div>
  </div>


</div>

  );
}

export default Static_API_OCR;